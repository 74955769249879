import Cookies from 'js-cookie'

export const REACT_APP_API_URL = 'https://api-preview.newtoninsights.app'
export const ERROR_MESSAGE_GENERIC =
	'Error getting data from API. Please contact the administrator.'

//** functions **//
export const setCookieItemWithExpiry = (key: string, value: string) => {
	Cookies.set(key, value, {expires: 1})
}

export function isMoreThanOneDay(dated: Date | undefined): boolean {
	if (!dated) {
		return false;
	}
	
	const givenDate = new Date(dated);
	const currentDate = new Date();
	
	// Ensure both givenDate and currentDate are valid Date objects
	if (isNaN(givenDate.getTime()) || isNaN(currentDate.getTime())) {
		return false; // or handle error as per your application logic
	}
	
	const timeDiff = Math.abs(currentDate.getTime() - givenDate.getTime());
	const diffDays = timeDiff / (1000 * 60 * 60 * 24);
	
	return diffDays > 1;
}