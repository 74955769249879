import React, {ReactNode, useContext} from 'react'
import {Navigate} from 'react-router-dom'
import AuthContext from './AuthContext'

const ProtectedRoute = ({children, accessBy}: {children: ReactNode, accessBy: 'authenticated' | 'non-authenticated' }) => {
	const authContext = useContext(AuthContext);
	
	if (!authContext) {
		// Handle the case where authContext is undefined
		return <Navigate to="/" />;
	}
	
	const { user } = authContext;
	
	if (accessBy === 'non-authenticated') {
		if (!user) {
			return <>{children}</>;
		} else {
			return <Navigate to="/Home" />;
		}
	} else if (accessBy === 'authenticated') {
		if (user) {
			return <>{children}</>;
		}
	}
	
	return <Navigate to="/" />;
}

export default ProtectedRoute