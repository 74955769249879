import jwtInterceoptor from './jwtInterceptor';

const getDevices = async () => {
	try {
		const apiResponse = await jwtInterceoptor.get(`${process.env.REACT_APP_API_URL}/user/api/device/`);
		console.log('Devices', apiResponse.data.results);
		localStorage.setItem('user_devices', JSON.stringify(apiResponse.data.results))
		return apiResponse.data?.results;
	} catch (error) {
		console.log(error)
		return null;
	}
}


export {getDevices};