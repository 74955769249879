import React, {useRef, useContext, useState, useEffect} from 'react'
import {
    CButton,
    CCol,
    CContainer,
    CForm,
    CFormInput,
    CInputGroup,
    CFormCheck,
    CInputGroupText,
    CRow,
} from '@coreui/react'
import '../../../assets/css/login.css'
import { toast } from 'react-toastify';
import CIcon from '@coreui/icons-react'
import { useNavigate } from 'react-router-dom'
import { cilLockLocked, cilUser } from '@coreui/icons'
import AuthContext from '../../shared/AuthContext'
import NewtonNoBG from '../../../assets/brand/NewtonInsightsBranding/NewtonNoBG.png'


import {getDevices} from '../../shared/devices'

const Login = () => {
    
    const authContext = useContext(AuthContext);
    if (!authContext) {
        throw new Error('useContext(AuthContext) must be used within a AuthContextProvider');
    }
    
    const { login, setUserName } = authContext
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error] = useState('')
    const loginButtonRef = useRef<HTMLButtonElement>(null);


    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
    }

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value)
    }

    const handleLogin = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (!email && !password) {
            toast.error("Please Enter the Email and Password");
            return
        }
        if (!email) {
            toast.error("Please Enter the Email ");
            return
        }
        if (!password) {
            toast.error("Please Enter the Password");
            return
        }

        try{
            // set form data
            const loginData = new FormData();
            loginData.append('username', email)
            loginData.append('password', password)
            setUserName(email)
            
            // call login from auth context
            await login(loginData)
            await getDevices();
        }
        catch (e: any) {
            toast.error(e.message);
        }
    }

    const handleForgotPassword = () => {
        navigate('/ForgotPassword');
    }

    useEffect(() => {
        const handleKeyDown = (event: { key: string; }) => {
            if (event.key === 'Enter') {
                // Focus the login button
                loginButtonRef.current?.focus();
            }
        };

      // Listen for keydown events on the window
      window.addEventListener('keydown', handleKeyDown);

      // Cleanup the event listener on component unmount
      return () => {
          window.removeEventListener('keydown', handleKeyDown);
      };
    }, []);

    const flexContainerStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    };

    return (
        <div className="bg-light min-vh-100 d-flex flex-row align-items-center set-background-image">
            <CContainer>
                <CRow className="justify-content-center">
                    <CCol md={5}>
                        <div className="p-4">
                            <CForm onSubmit={ handleLogin }>
                                <div className="image-container">
                                    <img src={NewtonNoBG} alt="Insights " />
                                </div>
                                <p className="main-header">Welcome to Newton Insights</p>
                                <p className=" sign-in-text">Sign In to your account</p>
                                <CInputGroup className="mb-3">
                                    <CInputGroupText>
                                       <CIcon icon={cilUser} />
                                    </CInputGroupText>
                                    <CFormInput
                                      placeholder="Email"
                                      autoComplete="email"
                                      value={email}
                                      onChange={handleEmailChange}
                                    />
                                </CInputGroup>
                                <CInputGroup className="mb-4">
                                    <CInputGroupText>
                                      <CIcon icon={cilLockLocked} />
                                    </CInputGroupText>
                                    <CFormInput
                                      type="password"
                                      placeholder="Password"
                                      name="userPassword"
                                      value={password}
                                      onChange={handlePasswordChange}
                                    />
                                </CInputGroup>

                                <div style={flexContainerStyle}>
                                    <div>
                                        <CFormCheck id="rememberMe" label="" />
                                            <small>
                                                <em>Remember me</em>
                                            </small>
                                    </div>

                                    <div>
                                        <button
                                            className="forgot-password"
                                            onClick={handleForgotPassword}
                                        >
                                            <small>Forgot Password</small>
                                        </button>
                                    </div>
                                </div>
                                {error && <p className="text-danger">{error}</p>}

                                <CRow className="button-container">
                                    <CButton ref={loginButtonRef} className="custom-button" type='submit'>
                                        Login
                                    </CButton>
                                </CRow>

                                <div className="button-container">
                                    <button
                                        className="register-link"
                                        style={{ cursor: 'pointer' }}
                                        onClick={()=> navigate('/register')}
                                    >
                                    New ? Register
                                  </button>
                                </div>
                            </CForm>
                            <h2 className="company-name">Powered by 8th Revolution </h2>
                        </div>
                    </CCol>
                </CRow>
            </CContainer>
        </div>
    );
}

export default Login