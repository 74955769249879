import React from "react";
import {useDialog} from "react-st-modal";

interface ScanResultsProps {
	question: string;
	yesOption: string;
	noOption: string | null;
	centerYesButton?: boolean;
}

const ScanResults: React.FC<ScanResultsProps> = ({ question, yesOption, noOption, centerYesButton = false }) => {

	const dialog = useDialog();
	
	return (
		<div className="GridComponentDiv">
			<br/>
			<h3
				style={{
					fontSize: "25px",
					fontWeight: "400",
					fontFamily: "Inter",
					color: "#000000",
				}}
			>
				{question}
			</h3>
			
			<div
				style={{
					width: "60%",
					display: "flex",
					justifyContent: "space-around",
					marginTop: "40px",
				}}
			></div>
			<button
				className={"btn btn-success" + (centerYesButton ? " d-block m-auto" : "")}
				onClick={() => {
					// Сlose the dialog and return the value 1
					dialog.close(1);
				}}
			>
				{yesOption}
			</button>
			{
				noOption &&
				<button
					className="btn btn-primary"
					onClick={() => {
						// Сlose the dialog and return the value 0
						dialog.close(0);
					}}
				>
					{noOption}
				</button>
			}
			<br/>
		</div>
	);
};

export default ScanResults;