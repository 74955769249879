import React from "react";
import RedCircleSvg from "./SVGs/RedCircleSvg";
import GreyCircleSvg from "./SVGs/GreyCircleSvg";
import GreenCircleSvg from "./SVGs/GreenCircleSvg";

type TestStatus = boolean | undefined | null;

const GetTestStatus: React.FC<{ test: TestStatus }> = ({ test }) => {
	
	if (typeof test === "boolean" && test) {
		return <GreenCircleSvg/>;
	} else if (typeof test === "boolean" && !test) {
		return <RedCircleSvg/>;
	} else {
		return <GreyCircleSvg/>;
	}
};

export default GetTestStatus;