import axios from 'axios'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import React, {useState, useEffect} from 'react'
import {CCol, CContainer, CRow} from '@coreui/react'
import {useNavigate, useParams} from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import NewtonNoBG from '../../../assets/brand/NewtonInsightsBranding/NewtonNoBG.png'

const ActivateAccount = () => {
	const navigate = useNavigate()
	const {id, token} = useParams()
	const [loading, setLoading] = useState(true)
	const [success, setSuccess] = useState(false)
	
	useEffect(() => {
		const activateAccount = async () => {
			try {
				await axios.get(
					`${process.env.REACT_APP_API_URL}/user/user/activate/${id}/${token}/`,
				)
				setSuccess(true)
				setTimeout(() => navigate('/'), 3000)
			} catch (error) {
				setSuccess(false)
			} finally {
				setLoading(false)
			}
		}
		
		activateAccount()
	}, [id, token, navigate])
	
	return (
		<div className="bg-light min-vh-100 d-flex flex-row align-items-center set-background-image">
			<CContainer>
				<CRow className="justify-content-center">
					<CCol md={5}>
						<Card>
							<div className="image-container" style={{textAlign: 'center', padding: '20px'}}>
								<img src={NewtonNoBG} alt="Insights" style={{maxWidth: '100%', height: 'auto'}}/>
							</div>
							<CardContent>
								{loading ? (
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											height: '150px',
										}}
									>
										<CircularProgress/>
									</div>
								) : success ? (
									<div style={{textAlign: 'center', padding: '20px'}}>
										<h4>Activation Successful</h4>
										<p>Your account has been activated Successfully </p>
									</div>
								) : (
									<div style={{textAlign: 'center', padding: '20px'}}>
										<h4>Activation Failed</h4>
										<p>There was an error activating your account</p>
									</div>
								)}
							</CardContent>
						</Card>
					</CCol>
				</CRow>
			</CContainer>
		</div>
	)
}

export default ActivateAccount