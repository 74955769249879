import React from "react";

const GreyCircleSvg = () => {
	return (
		<div title="Unknown">
			<svg
				width="10"
				height="10"
				viewBox="0 0 20 20"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				style={{marginRight: "10px"}}
			>
				<circle cx="10" cy="10" r="10" fill="#B3AEAE"/>
			</svg>
		</div>
	);
};

export default GreyCircleSvg;