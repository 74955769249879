import 'core-js'
import App from './App'
import React from 'react'
import store from './store'
import 'react-app-polyfill/stable'
import {Provider} from 'react-redux'
import {createRoot} from 'react-dom/client'
import reportWebVitals from './reportWebVitals'

// ! operator is added for not null assertion
//  this element will be present always
createRoot(document.getElementById('root')!).render(
	<Provider store={store}>
		<App/>
	</Provider>,
)

// @ts-ignore
reportWebVitals()