import React, {useContext, useState} from 'react'
import {
	CButton,
	CCard,
	CCardBody,
	CCol,
	CContainer,
	CForm,
	CFormInput,
	CInputGroup,
	CInputGroupText,
	CRow,
} from '@coreui/react'
import '../../../assets/css/register.css'
import {toast} from 'react-toastify';
import CIcon from '@coreui/icons-react'
import {cilLockLocked, cilUser, cilBuilding} from '@coreui/icons'
import AuthContext from '../../shared/AuthContext'
import NewtonNoBG from '../../../assets/brand/NewtonInsightsBranding/NewtonNoBG.png'

const Register = () => {

	const authContext = useContext(AuthContext);
	if (!authContext) {
		throw new Error('useContext(AuthContext) must be used within a AuthContextProvider');
	}
	const {register} = authContext;

	// State variables to manage form inputs
	const [lastName, setLastName] = useState('');
	const [firstName, setFirstName] = useState('');
	const [email, setEmail] = useState('');
	const [company, setCompany] = useState('');
	const [password, setPassword] = useState('');
	const [confpassword, setConfPassword] = useState('');

	// Function to handle the form submission
	const handleRegister = async (e: { preventDefault: () => void; }) => {
		e.preventDefault(); // Prevent the default form submission behavior

		// Validate the form inputs
		if (!firstName || !email || !company || !password || !confpassword) {
			toast.error("Please fill in all the fields", {hideProgressBar: true, autoClose: false});
			return;
		}
		if (password !== confpassword) {
			toast.error("Password and Confirm Password should be same", {hideProgressBar: true, autoClose: false});
			return;
		}

		// Create a FormData object to hold the form inputs
		const registerData = new FormData();
		registerData.append('name', firstName + lastName);
		registerData.append('first_name', firstName);
		registerData.append('last_name', lastName);
		registerData.append('email', email);
		registerData.append('company_name', company);
		registerData.append('password', password);
		registerData.append('password2', confpassword);

		// Call the register function with the form data
		await register(registerData);
	}

	// Return the JSX to render the registration form
	return (
		<div className="bg-light min-vh-100 d-flex flex-row align-items-center set-background-image">
			<CContainer>
				<CRow className="justify-content-center">
					<CCol>
						<div className="reg-image-container">
							<img src={NewtonNoBG} alt="Insights "/>
						</div>
						<p className="reg-main-header">
							Start your Journey with Newton Insights
						</p>
						<h2 className="reg-company-name hidden-on-small">
							Powered by 8th Revolution{" "}
						</h2>
					</CCol>
					<CCol md={5}>
						<CCard>
							<CCardBody className="p-4">
								<CForm onSubmit={handleRegister}>
									<h1>Register</h1>
									<p className="text-medium-emphasis">Create your account</p>
									<CRow className="mb-3">
										<CCol>
											<CInputGroup>
												<CInputGroupText>
													<CIcon icon={cilUser} />
												</CInputGroupText>
												<CFormInput
													placeholder="First Name"
													autoComplete="First Name"
													value={firstName}
													onChange={(e) => setFirstName(e.target.value)}
												/>
											</CInputGroup>
										</CCol>
										<CCol>
											<CInputGroup>
												<CInputGroupText>
													<CIcon icon={cilUser} />
												</CInputGroupText>
												<CFormInput
													placeholder="Last Name"
													autoComplete="Last Name"
													value={lastName}
													onChange={(e) => setLastName(e.target.value)}
												/>
											</CInputGroup>
										</CCol>
									</CRow>

									<CInputGroup className="mb-3">
										<CInputGroupText>@</CInputGroupText>
										<CFormInput
											placeholder="Email"
											autoComplete="email"
											value={email}
											onChange={(e) => setEmail(e.target.value)}
										/>
									</CInputGroup>
									<CInputGroup className="mb-3">
										<CInputGroupText>
											<CIcon icon={cilBuilding}/>
										</CInputGroupText>
										<CFormInput
											placeholder="Company Name"
											autoComplete="companyname"
											value={company}
											onChange={(e) => setCompany(e.target.value)}
										/>
									</CInputGroup>
									<CInputGroup className="mb-3">
										<CInputGroupText>
											<CIcon icon={cilLockLocked}/>
										</CInputGroupText>
										<CFormInput
											type="password"
											placeholder="Password"
											autoComplete="new-password"
											value={password}
											onChange={(e) => setPassword(e.target.value)}
										/>
									</CInputGroup>
									<CInputGroup className="mb-4">
										<CInputGroupText>
											<CIcon icon={cilLockLocked}/>
										</CInputGroupText>
										<CFormInput
											type="password"
											placeholder="Repeat password"
											autoComplete="new-password"
											value={confpassword}
											onChange={(e) => setConfPassword(e.target.value)}
										/>
									</CInputGroup>
									<CButton color="success" type="submit">Create Account</CButton>
									<a className="login-link" href='/'>
										Have account ? Login
									</a>
								</CForm>
							</CCardBody>
						</CCard>
					</CCol>
				</CRow>
			</CContainer>
		</div>
	);
}

export default Register;
