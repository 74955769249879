import React from 'react'
import {CFooter} from '@coreui/react'

const AppFooter = () => {
	return (
		<CFooter style={{
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: 'white'
		}}>
			<p style={{color: 'darkgrey'}}> 2024 Newton Insights @ All rights reserved</p>
		
		</CFooter>
	)
}

export default AppFooter
