import axios from 'axios'
import {toast} from 'react-toastify';

const jwtInterceoptor = axios.create({});

jwtInterceoptor?.interceptors.request.use((config) => {
	const tokens = localStorage.getItem('tokens') || "";
	let tokensData = tokens ? JSON.parse(tokens) : "";
	
	config.headers['Authorization'] = `Bearer ${tokensData?.token?.access}`

	return config
})

jwtInterceoptor?.interceptors.response.use(
	(response) => {
		return response
	},
	async (error) => {
		
		const isDemoEnvironment = window.location.href.includes('demo');
		
		if (error.code === 'ECONNABORTED' && error.message.includes('timeout')) {
			toast.error("Timeout Please Try Again", {hideProgressBar: true, autoClose: 5000});
		}
		if (error?.response?.status === 500) {
			// hide errors on demo env
			if (isDemoEnvironment){
				toast.error(`${error?.code || "Error"} : ${error?.response?.data?.message || "Error at webserver end"}`, {
					hideProgressBar: true,
					autoClose: 5000
				});
			}
		}
		//#TODO: Need to remove 400 after making sure that no endpoint is returning 400 for token expiry (ib /Calibrate end-point is returning that as of now)
		if (error.response?.status === 401) {
			const authData = JSON.parse(localStorage.getItem('tokens') || '')
			if (!authData) {
				return Promise.reject(error)
			}
			const payload = {
				access: authData?.token?.access,
				refresh: authData?.token?.refresh,
			}
			
			// Verify if the token is still valid
			try {
				await axios.post(`${process.env.REACT_APP_API_URL}/user/token/verify/`, {token: authData?.token?.access});
				// If no error was thrown, the token is still valid.
				// The error might be due to something else, so handle accordingly.
			} catch (verificationError: any) {
				// the token is invalid, try to refresh it
				if (verificationError.response.status === 401) {
					//  token refresh logic
					try {
						
						let apiResponse = await axios.post(
							`${process.env.REACT_APP_API_URL}/user/token/refresh/`,
							payload,
						)
						const tokens = {
							token: {
								access: apiResponse.data.access,
								refresh: authData.token.refresh, // refresh token is old
							},
						}
						localStorage.setItem('tokens', JSON.stringify(tokens))
						error.config.headers['Authorization'] = `Bearer ${apiResponse.data.access}`
						return axios(error.config)
					} catch (error) {
						toast.error("Authentication Expired !! Please Login again", {
							hideProgressBar: true,
							autoClose: false
						});
						console.log(error)
						localStorage.removeItem('tokens')
						window.location.href = '/'
					}
				}
				
			}
			
			
		} else {
			if (error?.response?.data?.message) {
				let combinedError = 'Error!';
				if (Array.isArray(error?.response?.data?.message)) {
					combinedError = (error?.response?.data?.message).join('\n');
				} else {
					combinedError = error?.response?.data?.message;
				}
				toast.error(`${combinedError}`, {hideProgressBar: true, autoClose: 5000});
			}
			return Promise.reject(error)
		}
	},
)

export default jwtInterceoptor;