import React from 'react';
import { TiPlug } from 'react-icons/ti';
import GetTestStatus from '../GetTestStatus';
import { CiCircleCheck } from 'react-icons/ci';
import CreatableSelect from 'react-select/creatable';
import { BsExclamationCircle } from 'react-icons/bs';
import { CCard, CCardBody, CCardTitle, CCardText, CRow, CCol, CButton } from '@coreui/react';

// Define the types for the props
interface Connection {
	id: string;
	machine_name: string;
	dated?: string;
}

type SetSelectedConnection = (connection: Connection | undefined) => void;

interface SelectYourHardwareProps {
	selectedConnection?: Connection;
	setSelectedConnection: SetSelectedConnection;
	connections: Connection[];
	deviceStatus: boolean;
	handleConnections: () => void;
}

const SelectYourHardware: React.FC<SelectYourHardwareProps> = ({selectedConnection, setSelectedConnection, connections, deviceStatus, handleConnections}) => {
	return (
		<CCard style={{backgroundColor: "#DAEEF5"}}>
			<CCardBody>
				<CCardTitle>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							height: "150px",
						}}
					>
						<TiPlug
							style={{
								color: "#1488f5",
								height: "150px",
							}}
							size="100"
						/>
					</div>
					<h4
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						Select Your Hardware
					</h4>
				</CCardTitle>
				<div
					style={{
						width: "100%",
						marginLeft: "10px",
						marginBottom: "10px",
					}}
				>
					<CreatableSelect
						id="device"
						className="classic"
						value={
							selectedConnection
								? {
									value: selectedConnection.id,
									label: selectedConnection.machine_name,
								}
								: null
						}
						placeholder="Select Device"
						options={connections?.map((device) => {
							return {value: device.id, label: device.machine_name};
						})}
						isSearchable={true}
						formatCreateLabel={(inputValue) => inputValue}
						onChange={(selectedOption) => {
							if(selectedOption){
								setSelectedConnection(
									connections.find(
										(conn) => conn.id === selectedOption.value
									)
								);
							}
						}}
					/>
				</div>
				
				<CCardText>
					<CRow
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<CCol
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
							}}
						>
							<CRow>
								<small style={{color: "grey"}}>Status</small>
							</CRow>
							
							<CRow>
								<small
									style={{
										fontFamily: "Expansiva, sans-serif",
										display: "flex",
										flexDirection: "row",
									}}
								>
									<GetTestStatus test={deviceStatus}/>
									{deviceStatus ? "Active" : "Inactive"}
								</small>
							</CRow>
						</CCol>
						<CCol
							style={{
								display: "flex",
								justifyContent: "center",
								alignItems: "center",
								flexDirection: "column",
							}}
						>
							<CRow>
								<small style={{color: "grey"}}>Previous Date</small>
							</CRow>
							
							<CRow>
								<small style={{fontFamily: "Expansiva, sans-serif", fontSize: "0.7rem"}}>
									{selectedConnection?.dated
										?.replace(/-/g, ".")
										.slice(0, 10)}
								</small>
							</CRow>
						</CCol>
					</CRow>
				</CCardText>
				<div
					style={{
						width: "100%",
						display: "flex",
						justifyContent: "center",
					}}
				>
					<CButton
						className="centeralized no-margin"
						style={{ backgroundColor: !deviceStatus ? "red" : undefined }}
						color="primary"
						onClick={handleConnections}
					>
						{!deviceStatus ? (
							<BsExclamationCircle
								style={{marginRight: "5px"}}
								size="20"
							/>
						) : (
							<CiCircleCheck style={{marginRight: "5px"}} size="20"/>
						)}
						{deviceStatus ? 'Completed' : 'Connect Hardware'}
					</CButton>
				</div>
			</CCardBody>
		</CCard>
	);
};

export default SelectYourHardware;
